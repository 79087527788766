import { Link } from "gatsby"
import styled from "styled-components"
import React, { useState, useEffect } from "react"

const StyledLink = styled(Link)`
    font-size: 14px;
`;

const CookieDiv = styled.div`
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 100%;
    background: red;

    &.hide {
        display: none;
    }

    > div {
        max-width: 1110px;
        margin: 20px auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    p { 
        font-size: 14px;
        width: 60%;
        margin-bottom: 0;

        @media only screen and (max-width: 1140px) {
            width: 100%;
            padding: 0 20px 20px 20px;
        }
    }

    .button-group {
        display: flex;
        justify-content: end;
        width: 40%;
        
        @media only screen and (max-width: 1140px) {
            width: 100%;
            justify-content: center;
        }
    }

    button {
        max-width: 156px;
        height: 40px;
        font-size: 14px;
        line-height: 14px;
        margin-top: 0; 
        margin-left: 20px;

        &.outline {
            background: var(--cookie-bar);
        }
    }
`;

function allConsentGranted() {
    let targetCookieBarElement = document.querySelectorAll('.bg-cookie-bar')[0];
    targetCookieBarElement.classList.add('hide');
   
    window.gtag('consent', 'update', {
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'ad_storage': 'granted',
      'analytics_storage': 'granted'
    });

    localStorage.setItem("cookieBannerConsent", "true");
  }


  function noConsentGranted() {
    let targetCookieBarElement = document.querySelectorAll('.bg-cookie-bar')[0];
    targetCookieBarElement.classList.add('hide');
    localStorage.setItem("cookieBannerConsent", "false");
  }


const CookieBar = () => {
    const [showCookieBar] = useState(false);
   
    useEffect(() => {
        if(!localStorage.getItem("cookieBannerConsent") == true){
            let targetCookieBarElement = document.querySelectorAll('.bg-cookie-bar')[0];
            targetCookieBarElement.classList.remove('hide');
        }
    }, [showCookieBar]);

    return (
        <>
        <CookieDiv className="bg-cookie-bar hide" showCookieBar={showCookieBar}>
            <div>
                <p className="text-white"> This website utilizes technologies such as cookies to enable essential site functionality, as well as for analytics, personalization, and targeted advertising. <StyledLink to="/company/privacy"> More information about your privacy </StyledLink>
                </p>
                <div className="button-group">
                    <button className="outline" onClick={noConsentGranted} type="submit">Decline</button>
                    <button type="submit" onClick={allConsentGranted}>Accept</button>
                </div>
            </div>
        </CookieDiv>
        </>
    );
}

export default CookieBar