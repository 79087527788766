import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const WrapperDiv = styled.div`
    max-width: 1110px;
    margin: 60px auto 0;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;

    a {
        width: 255px;
        display: block;
        float:left;
    }

    .info {
        margin: 60px auto;
    }

    @media only screen and (max-width: 1140px) {
        margin-top: 0;
        margin-bottom: 0;

        a {
            width: 100%;
            display: block;
            float:left;
        }

        .info {
            max-height: 140px;
        }
    }

    #cannabisess {
        height: 50px; 
        margin-bottom: 0;   
    }

`;

const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-bottom: 100px;
    @media only screen and (max-width: 1024px) {
        margin-bottom: 90px;
    }
`;

const Content = styled.div `
    width: 60%;
    padding-top: 60px;

    @media only screen and (max-width: 1140px) {
        width: 100%;
        padding: 0;
        padding-top: 60px;
    }

    &.benefits {
        width: 40%;
        padding-left: 60px;

        @media only screen and (max-width: 1140px) {
            width: 100%;
            padding-left: 0;
            padding-top: 20px;
        }
    }
`;

const Benefits = styled.div `
    @media only screen and (max-width: 1140px) {
        width: 100%;
        padding: 0;
        margin-top: 0;
    }
`;

const BenefitsUl = styled.ul`
    list-style: none;
    max-width: 350px;
    margin-bottom: 30px;

    @media only screen and (max-width: 1140px) {
        max-width: unset;
    }
    li {
        padding-left: 0px;
        position: relative;

        &:before {
            content: "•";
            position: absolute;
            left: -20px;
            padding-right: 8px;
            font-size: 30px;
            margin-top: -2px;
        }
    }
`

const VideoBlock = styled.div `
    padding-top: 20px;
`;

const VideoIFrame = styled.iframe `
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 450px;
    margin-bottom: 0;
    border: 0;

    @media only screen and (max-width: 1140px) {
        width: 100%;
        height: 280px!important;
        min-height: 280px;
    }
`;

const DetailFlex = styled.div`
    display: flex;
    justify-content: center;
    background: #E5F5FB;
    margin-bottom: 100px;
    @media only screen and (max-width: 1140px) {
        padding: 0 20px;
        margin-bottom: 60px;
    }

    #rootStock {
        width: 250px;
        margin-right: 50px;
    }
`;

const DetailText = styled.div`
    padding: 47px 0 60px;
    max-width: 1110px;
    display: flex;
    flex-wrap: wrap;

    p {
        width: 100%;
    }

    @media only screen and (max-width: 1140px) {
        padding: 0 0 100px;

        p {
            width: 100%;
            margin-top: 35px;
        }
    }
`;

class ContentClass extends React.Component {
    constructor(props) {
        super();
        this.location = props.location.location;
        const params = new URLSearchParams(this.location.search)

        this.state = {
            watch_video: this.location.search && this.location.search.includes('watch_video'),
            tracking_source: this.location.search && this.location.search.includes('utm_source') ? params.get('utm_source') : 'Other',
            submitted: this.location.search && this.location.search.includes('submit')
        };
    }

    render() {
        return (
            <>
                <DetailFlex>
                <DetailText>
                    <p className="intro">We have extensive experience implementing, customizing, and supporting all components of the Certinia (formerly FinancialForce) solution with an emphasis on Inventory Management</p>
                </DetailText>
                </DetailFlex>
                <WrapperDiv>
                    <Flex>
                        <Content>
                            <p>Ladd Partners simplifies Cycle Count management for total visibility and greater flexibility. Ladd Partners Enhanced Cycle Counting enables companies to confirm and reconcile their inventory balances with their Certinia General Ledger</p>
                            <p>Enhanced Cycle Counting saves time and improves accuracy during the counting and confirmation process with advanced features. </p>
                            <VideoBlock id="video">
                                <VideoIFrame src="https://player.vimeo.com/video/869745428" className="videoIframe" width="560" height="315" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></VideoIFrame>
                            </VideoBlock>
                        </Content>

                    <Content className="benefits">
                        <Benefits>
                                <p className="intro margin-bottom-10">Product Features</p>
                                <BenefitsUl>
                                    <li>Create Cycle Counts by: Warehouse, Location and ABC Classification</li>
                                    <li>Ability to generate variance reports before making adjustments</li>
                                    <li>Allows for blind cycle counts with manager overrides</li>
                                    <li>Support for serialized and non serialized inventory</li>
                                    <li>Supports paperless data entry for Cycle Counts via Mobile devices</li>
                                </BenefitsUl>
                            </Benefits>
                            <Link to="/solutions/enhancedcyclecounting-trial"><button>Request a Free Trial</button></Link>
                    </Content>
                    </Flex>
            </WrapperDiv>`
        </>
        )
    }
}

export default ContentClass
