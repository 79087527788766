import React from "react"
import Header from "../components/header-new"
import CookieBar from "../components/cookie-bar"
import Footer from "../components/footer-cannabis"
import { Helmet } from "react-helmet"
import "../assets/index.css"
import ogImage from "../images/og_image.png"
import twitterImage from "../images/twitter_port.png"
import { Location } from '@reach/router'

function getTitle(props) {
    return props.title;
}

function getOgImage(props) {
    let og_image_url = "https://laddpartners.com" + ogImage;

    if(props.og_image_url != null) {
        og_image_url = props.og_image_url.asset.url;
    }
    return og_image_url;
}

function getTwitterImage(props) {
    let twitter_image_url = "https://laddpartners.com" + twitterImage;

    if(props.twitter_image_url != null) {
        twitter_image_url = props.twitter_image_url.asset.url;
    }
    return twitter_image_url;
}


function getOgDescription(props) {
    let description = "Full Service Consulting for CERTINIA (Formally FINANCIALFORCE) & SALESFORCE -  Implementation, Support, Custom Development and System Health Checks";

    if(props.social_description != null) {
        description = props.social_description;
    }
    return description;
}

function getOgTitle(props) {
    let title = "Ladd Partners";

    if(props.social_title != null) {
        title = props.social_title + " | Ladd Partners";
    }
    return title;
}


const Layout = ({ children, location, ...props }) => (
    <>
       <Helmet>
            <meta charSet="utf-8" />
            <title>{getTitle(props)}</title>


            <Location>
                {({ location }) => {
                    return  <> <link rel="canonical" href={`https://laddpartners.com/${location.pathname}`} /></>
                }}
            </Location>
        
           
            <meta name="description" content={getOgDescription(props)} />
            <meta property="og:url" content="https://laddpartners.com"/>
            <meta name="google-site-verification" content="eWprNZ0H3E-kXMnAh_XYftne306sQ7TsFfP6c_1j2FU" />
            <meta property="og:title" content={getTitle(props)} />
            <meta property="og:description" content={getOgDescription(props)} />
            <meta property="og:image" content={getOgImage(props)} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={getOgTitle(props)} />
            <meta name="twitter:description" content={getOgDescription(props)} />
            <meta name="twitter:url" content="https://twitter.com/LaddPartners" />
            <meta name="twitter:image" content={getTwitterImage(props)}/>

            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-145886969-1"></script>
            <script>
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'UA-145886969-1');
                
                if(!localStorage.getItem("cookieBannerConsent") == true){
                    gtag('consent', 'default', {
                        'ad_storage': 'denied',
                        'ad_user_data': 'denied',
                        'ad_personalization': 'denied',
                        'analytics_storage': 'denied'
                    });
                } else {
                    window.gtag('consent', 'update', {
                        'ad_user_data': 'granted',
                        'ad_personalization': 'granted',
                        'ad_storage': 'granted',
                        'analytics_storage': 'granted'
                      });
                }
            `}
            </script>
        </Helmet>

        <main>
            <Header/>
            {children}
            <Footer/>
        </main>
        <CookieBar/>
    </>
)

export default Layout